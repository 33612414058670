.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.cursor-pointer{
    cursor: pointer;
}

.cursor-pointer:hover {
    color: #383A3C;
}

.table-header-center th {
    text-align: center;
}

.visibility-partial {
    background-color: #83ADE8 !important;
}

.visibility-full {
    background-color: #7E8081 !important;
}
