.ump-calendar-title h3 {
    font-size: 1.3rem !important;
}

@media (min-width: 768px) {
    .ump-calendar-title h3 {
        font-size: 1.7rem !important;
    }
}

.ump-absolute-md {
    position: relative !important;
}

@media (min-width: 991px) {
    .ump-absolute-md {
        position: absolute !important;
    }
}

.ump-absolute-right {
    right: 0
}


.ump-main-game-banner h1 {
    font-size: 2.5rem !important;
}

.ump-main-game-banner h2 {
    font-size: 1.3rem !important;
}

@media (min-width: 768px) {
    .ump-main-game-banner h1 {
        font-size: 3rem !important;
    }
    .ump-main-game-banner h2 {
        font-size: 1.45rem !important;
    }
}

@media (min-width: 991px) {
    .ump-main-game-banner h1 {
        font-size: 3.5rem !important;
    }
    .ump-main-game-banner h2 {
        font-size: 1.65rem !important;
    }
}

@media (min-width: 1200px) {
    .ump-game-header h1 {
        font-size: 4rem !important;
    }
    .ump-game-header h2 {
        font-size: 1.8rem !important;
    }
}

.pfp-square {
    width: 100%;
    height: auto
}

.ump-max-width {
    min-width: 100%;
}