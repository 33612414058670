.debug {border: 1px solid blue}

.ump-loading-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ump-loading-container {
    opacity: .5;
    pointer-events: none
}
